





























































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { HeaderDropdown } from '~/models/header/header-dropdown'
import CNewBadge from '~/components/shared/configurable/badge/CNewBadge.vue'

export default defineComponent({
  components: {
    CNewBadge
  },
  props: {
    dropdown: {
      type: Object as PropType<HeaderDropdown>,
      required: true
    }
  },
  setup() {
    const shown = ref(false)
    const enter = () => {
      shown.value = true
    }
    const leave = () => {
      shown.value = false
    }
    return {
      shown,
      enter,
      leave
    }
  }
})
