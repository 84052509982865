import { CIcon } from '~/icons/types'

export const ciGreek: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12.871 5.383v2.561H7.488v5.44H23.92a12.187 12.187 0 0 0 0-2.769H12.871V8.054h10.466a12.095 12.095 0 0 0-1.39-2.77H12.87V2.718h6.733A11.964 11.964 0 0 0 12 0a11.95 11.95 0 0 0-4.512.877v4.506h5.383ZM4.921 7.95H.704a11.927 11.927 0 0 0-.623 5.435h4.84V7.95Zm0-5.642a12.1 12.1 0 0 0-2.936 3.075H4.92V2.308Zm18.416 13.638H.663c.347.987.814 1.915 1.39 2.77H21.94a11.658 11.658 0 0 0 1.396-2.77ZM4.396 21.283A11.963 11.963 0 0 0 12 24c2.885 0 5.533-1.021 7.604-2.717H4.396Z',
      fill: '#0D5EAF'
    },
    {
      d:
        'M12.871 5.285h9.075a12.04 12.04 0 0 0-2.336-2.562h-6.74v2.562Zm0 5.331H23.92c-.104-.889-.3-1.749-.588-2.562H12.87v2.562Zm9.075 8.1H2.054a12.042 12.042 0 0 0 2.336 2.561h15.214c.9-.733 1.69-1.592 2.342-2.561Z',
      fill: '#fff'
    },
    {
      d:
        'M23.92 13.385H7.488V7.95h5.382V5.383H7.488V.877c-.917.375-1.776.854-2.567 1.43v3.076h-2.93A12.243 12.243 0 0 0 .703 7.944h4.223v5.44H.081c.104.89.3 1.749.588 2.562h22.668a12.08 12.08 0 0 0 .582-2.561Z',
      fill: '#fff'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'greek',
  type: 'brand'
}
