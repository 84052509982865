export interface NotificationEvent {
  event: number
  project: string
  title: string
  type: NotificationTypeEnum
  image: string
  classified: {
    isDeleted: false
    thumb: string
    title: string
    type: string
    descriptiveTitle: string
    id: number
  }
  created: string
  isRead: boolean
  isSent: boolean
  action: number
  message: string
  data: {
    oldPrice: number
    newPrice: number
  }
  id: number
  classifiedId: Array<number>
  messageType: string
  targetUrl?: string
}
export interface NotificationType {
  count: number
  name: string
  value: NotificationTypeEnum
}

export enum NotificationTypeEnum {
  NEW_CLASSIFIED = 'new_classified',
  CLASSIFIED_DELETION = 'classified_deletion',
  CLASSIFIED_PARKING_OFFER = 'classified_parking_offer',
  CLASSIFIED_DESCRIPTION_CHANGE = 'classified_description_change',
  CLASSIFIED_PRICE_REDUCTION = 'classified_price_reduction',
  NEW_USER_MESSAGE = 'new_user_message'
}
