import { inject } from 'tsyringe'
import VueI18n from 'vue-i18n'
import { Store } from 'vuex'

import { containerScoped } from '~/decorators/dependency-container'
import { LocaleCode } from '~/models/locale/types'
import { I18nLocale } from '~/models/shared/types'
import { RootState } from '~/store/types'

@containerScoped()
export default class LocaleService {
  constructor(
    @inject(VueI18n) private i18n: VueI18n,
    @inject(Store) private store: Store<RootState>
  ) {}

  getLocaleLabel(code: LocaleCode): string {
    switch (code) {
      case 'el': {
        return 'Ελληνικά' as string
      }
      case 'en': {
        return 'English' as string
      }
      case 'de': {
        return 'German' as string
      }
      case 'bg': {
        return 'Bulgarian' as string
      }
      default: {
        return 'Ελληνικά' as string
      }
    }
  }

  getLocaleIcon(code: LocaleCode): string {
    switch (code) {
      case 'el': {
        return require('~/assets/img/flags/1x1/el.svg') as string
      }
      case 'en': {
        return require('~/assets/img/flags/1x1/en.svg') as string
      }
      case 'de': {
        return require('~/assets/img/flags/1x1/de.svg') as string
      }
      case 'bg': {
        return require('~/assets/img/flags/1x1/bg.svg') as string
      }
      default: {
        return ''
      }
    }
  }

  get locale(): I18nLocale {
    return this.store.state.i18n.locale
  }
}
