

























import {
  defineComponent,
  computed,
  ref,
  watch,
  useRoute,
  useFetch,
  onMounted,
  onBeforeMount
} from '@nuxtjs/composition-api'
import { PAGE_NS, PageState } from '~/store/modules/shared/page/state'
import CarHeader from '~/components/car/header/CarHeader.vue'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { PARKING_NS, ParkingState } from '~/store/modules/shared/parking/state'
import { getRouteMetaItem } from '~/utils/router'
import CarFooter from '~/components/car/footer/CarFooter.vue'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import HeadService from '~/services/HeadService'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import FlashService from '~/services/flash/FlashService'
import AdmanAdsService from '~/services/ads/AdmanAdsService'
import { useUserAgent } from '~/compositions/user-agent'
import { useNamespacedStore } from '~/compositions/store'
import { useDeps } from '~/compositions/dependency-container'
import {
  LOGIN_MODAL_NS,
  LoginModalState
} from '~/store/modules/shared/login-modal/state'
import AppNotifications from '~/components/shared/configurable/notification-center/AppNotifications.vue'
import AppConfirmationModal from '~/components/shared/configurable/modal/AppConfirmationModal.vue'
import AppGlobalAlerts from '~/components/car/AppGlobalAlerts.vue'
import AppSnackbar from '~/components/car/AppSnackbar.vue'
import GoogleOneTap from '~/components/shared/social-login/GoogleOneTap.vue'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { defineVue3AsyncComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    GoogleOneTap,
    AppSnackbar,
    AppNotifications,
    CarFooter,
    ParkingModalList: defineVue3AsyncComponent(() =>
      import('~/pages/shared/account/parking/modal-list.vue')
    ),
    CLoginModal: defineVue3AsyncComponent(() =>
      import('~/components/shared/login/CLoginModal.vue')
    ),
    CarHeader,
    AppConfirmationModal,
    AppGlobalAlerts
  },
  setup() {
    const route = useRoute()
    const { isMobile } = useUserAgent()
    const [
      languageSwitcherService,
      admanAdsService,
      flashService,
      dealerSiteService
    ] = useDeps(
      LanguageSwitcher,
      AdmanAdsService,
      FlashService,
      DealerSiteService
    )
    const { state: loginModalState } = useNamespacedStore<LoginModalState>(
      LOGIN_MODAL_NS
    )
    const { state: classifiedSearchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )
    const { dispatch: pageDispatch } = useNamespacedStore<PageState>(PAGE_NS)
    const {
      getters: parkingGetters,
      dispatch: parkingDispatch
    } = useNamespacedStore<ParkingState>(PARKING_NS)

    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const isSingleOrAnon = computed(() => userGetters('isSingleOrAnon'))

    const availableLocales = ref(languageSwitcherService.getAvailableLocales())

    useFetch(() => {
      if (isIframe.value) {
        pageDispatch('addBodyClass', 'iframe')
      }
      if (isSubdomain.value) {
        pageDispatch('addBodyClass', 'subdomain')
      }
    })

    const isLoginModalVisible = computed(
      () => loginModalState.isLoginModalVisible
    )

    const currentRoute = computed(() => classifiedSearchState.currentRoute)

    const showParking = computed(() => parkingGetters('getShowParking'))

    const isIframe = computed(() => {
      return getRouteMetaItem(route.value, 'isIframe')
    })

    const isSubdomain = computed(() => {
      return (
        getRouteMetaItem(route.value, 'isSubdomain') ||
        route.value.name?.startsWith('__subdomain')
      )
    })

    const isDealerSite = computed(() => {
      return dealerSiteService.routeIsOfDealerSite()
    })

    const isNoHeaderRoute = computed(() => {
      return getRouteMetaItem(route.value, 'noHeader')
    })

    const isNoFooterRoute = computed(() => {
      return getRouteMetaItem(route.value, 'noFooter')
    })

    const useWideContainer = computed(() => {
      return getRouteMetaItem(route.value, 'wideContainer')
    })

    const showFooter = computed(() => {
      return (
        !isIframe.value &&
        !isSubdomain.value &&
        !isDealerSite.value &&
        !isNoFooterRoute.value
      )
    })

    const showHeader = computed(() => {
      return (
        !isIframe.value &&
        !isSubdomain.value &&
        !isDealerSite.value &&
        !isNoHeaderRoute.value
      )
    })

    const showGoogleOneTap = computed(() => {
      return (
        process.env.GOOGLE_OAUTH_CLIENT_ID &&
        isSingleOrAnon.value &&
        getRouteMetaItem(route.value, 'showOneTap')
      )
    })

    const showGlobalAlerts = computed(() => {
      return (
        !isIframe.value &&
        !isSubdomain.value &&
        !isDealerSite.value &&
        !inTrades.value &&
        !isLanding.value &&
        !(inMap.value && isMobile.value) &&
        !isNoFooterRoute.value &&
        !isNoHeaderRoute.value
      )
    })

    const inMap = computed(() => {
      return getRouteMetaItem(route.value, 'inMap')
    })

    const inTrades = computed(() => {
      return getRouteMetaItem(route.value, 'inTrades')
    })

    const isLanding = computed(() => {
      return getRouteMetaItem(route.value, 'isLanding')
    })

    onBeforeMount(() => {
      flashService.setClientFlashFromCookie()
    })

    onMounted(() => {
      window.history.scrollRestoration = 'auto'
    })

    watch(currentRoute, val => {
      availableLocales.value = languageSwitcherService.getAvailableLocales(val)
    })

    watch(route, val => {
      availableLocales.value = languageSwitcherService.getAvailableLocales()
      if (process.client) {
        admanAdsService.refreshInnovativeFormats(val)
      }
    })

    function hideParkingModal() {
      parkingDispatch('hide_parking')
    }

    return {
      isMobile,
      currentRoute,
      showParking,
      availableLocales,
      isIframe,
      isSubdomain,
      hideParkingModal,
      useWideContainer,
      showFooter,
      showHeader,
      showGoogleOneTap,
      showGlobalAlerts,
      inMap,
      isLoginModalVisible
    }
  },
  head() {
    const headService = this.$dep(HeadService)
    return headService.createLayoutHead()
  }
})
