













import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { PARKING_NS, ParkingState } from '~/store/modules/shared/parking/state'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'
import { ciParkingP } from '~/icons/source/solid/parking-p'

export default defineComponent({
  setup() {
    const route = useRoute()
    const {
      getters: parkingGetters,
      dispatch: parkingDispatch
    } = useNamespacedStore<ParkingState>(PARKING_NS)
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
      USER_AGENT_NS
    )

    const isPc = computed(() => userAgentGetters('isPc'))
    const hasParked = computed(() => userGetters('getHasParked'))
    const showParking = computed(() => parkingGetters('getShowParking'))
    const showParkingModal = () => parkingDispatch('show_parking')
    const hideParkingModal = () => parkingDispatch('hide_parking')

    const parkingClicked = (event: Event) => {
      if (isPc.value) {
        event.preventDefault() // prevent link click

        if (
          route.value.name &&
          (route.value.name.startsWith('__parking') ||
            route.value.name.startsWith('__account_parking'))
        ) {
          // already in parking full page
          return
        }

        if (showParking.value) {
          hideParkingModal()
        } else {
          showParkingModal()
        }
      }
    }

    return {
      ciParkingP,
      hasParked,
      parkingClicked
    }
  }
})
