import { CIcon } from '~/icons/types'

export const ciUserCircle: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 1.3C6.1 1.3 1.2 6.1 1.2 12c0 3.1 1.3 6 3.6 8 2 1.7 4.5 2.7 7.1 2.7 2.6 0 5.2-1 7.1-2.7 2.3-2 3.6-5 3.6-8C22.8 6.1 17.9 1.3 12 1.3Zm0 20c-2 0-4-.7-5.6-1.9C7.6 17.9 8.9 17 12 17c3.1 0 4.4.9 5.6 2.4-1.6 1.2-3.6 1.9-5.6 1.9Zm6.7-2.9c-1.4-1.7-3-2.9-6.7-2.9s-5.4 1.3-6.7 2.9c-1.6-1.7-2.5-4-2.5-6.4 0-5.1 4.1-9.2 9.2-9.2s9.2 4.1 9.2 9.2c0 2.4-.9 4.6-2.5 6.4Z'
    },
    {
      d:
        'M12 5.7c-2.3 0-4.1 1.8-4.1 4.1s1.8 4.1 4.1 4.1 4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1Zm0 6.7c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6 0 1.4-1.2 2.6-2.6 2.6Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'user-circle',
  type: 'light'
}
