






















import { defineComponent, computed } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import {
  AppConfirmationModalState,
  APP_CONFIRMATION_MODAL_NS
} from '~/store/modules/shared/app-confirmation-modal/state'

export default defineComponent({
  setup() {
    const { state, commit } = useNamespacedStore<AppConfirmationModalState>(
      APP_CONFIRMATION_MODAL_NS
    )

    const options = computed(() => state.options)
    const isVisible = computed(() => state.isVisible)

    function handleCancel() {
      if (options.value?.onCancel) {
        options.value.onCancel()
      }
      commit('HIDE_CONFIRMATION_MODAL')
    }

    function handleHide() {
      commit('HIDE_CONFIRMATION_MODAL')
    }

    function handleOk() {
      if (options.value?.onConfirm) {
        options.value.onConfirm(options.value.params)
      }
    }

    return { options, isVisible, handleCancel, handleHide, handleOk }
  }
})
