import { CIcon } from '~/icons/types'
export const ciHamburger: CIcon = {
  width: '21',
  height: '20',
  paths: [
    {
      d:
        'M.75 4.188h19.5a.75.75 0 0 0 .75-.75V1.562a.75.75 0 0 0-.75-.75H.75a.75.75 0 0 0-.75.75v1.875c0 .415.336.75.75.75Zm0 7.5h19.5a.75.75 0 0 0 .75-.75V9.062a.75.75 0 0 0-.75-.75H.75a.75.75 0 0 0-.75.75v1.876c0 .414.336.75.75.75Zm0 7.5h19.5a.75.75 0 0 0 .75-.75v-1.875a.75.75 0 0 0-.75-.75H.75a.75.75 0 0 0-.75.75v1.875c0 .414.336.75.75.75Z',
      fill: '#fff'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'hamburger',
  type: 'regular'
}
