import { CIcon } from '~/icons/types'

export const ciUserCircle: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 1C5.9 1 1 5.9 1 12c0 3.1 1.3 6.1 3.7 8.2a10.913 10.913 0 0 0 14.6 0c2.3-2.1 3.7-5.1 3.7-8.2 0-6.1-4.9-11-11-11Zm0 20c-1.9 0-3.7-.6-5.2-1.7 1.1-1.3 2.4-2.1 5.2-2.1 2.8 0 4.1.8 5.2 2.1-1.5 1.1-3.3 1.7-5.2 1.7Zm6.7-3c-1.4-1.6-3.1-2.8-6.7-2.8S6.6 16.4 5.3 18C3.8 16.3 3 14.2 3 12c0-5 4-9 9-9s9 4 9 9c0 2.2-.8 4.3-2.3 6Z'
    },
    {
      d:
        'M12 5.4c-2.4 0-4.3 1.9-4.3 4.3S9.6 14 12 14s4.3-1.9 4.3-4.3c0-2.3-1.9-4.3-4.3-4.3Zm0 6.7c-1.3 0-2.3-1-2.3-2.3 0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3 0 1.3-1 2.3-2.3 2.3Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'user-circle',
  type: 'regular'
}
