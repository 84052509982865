






























import { getRouteMetaItem } from '~/utils/router'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useRoute
} from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import {
  SNACKBAR_NS,
  SnackbarState
} from '~/store/modules/shared/snackbar/state'
import { SET_SNACKBAR } from '~/store/modules/shared/snackbar/mutation-types'

export default defineComponent({
  setup() {
    const { state: snackbarState, commit: snackbarCommit } = useNamespacedStore<
      SnackbarState
    >(SNACKBAR_NS)
    const route = useRoute()

    const snackbar = computed(() => snackbarState.snackbar)
    const iframeStyle = ref(null)

    const isCrossOrigin = computed(() =>
      getRouteMetaItem(route, 'isCrossOrigin')
    )

    onMounted(() => {
      if (!isCrossOrigin.value && window.parent && window.parent !== window) {
        window.parent.addEventListener('scroll', onIframeScroll, false)
      }
    })

    onUnmounted(() => {
      if (isCrossOrigin.value && window.parent && window.parent !== window) {
        window.parent.removeEventListener('scroll', onIframeScroll, false)
      }
    })

    function onSnackbarClick() {
      snackbarCommit(SET_SNACKBAR, null)
    }

    function onActionClick() {
      const callback = snackbar.value?.options.action?.callback

      if (callback) {
        callback()
      } else {
        snackbarCommit(SET_SNACKBAR, null)
      }
    }

    function onIframeScroll() {
      const offset = 50

      let bottom =
        window.parent.document.body.scrollHeight -
        window.parent.innerHeight -
        window.parent.document.documentElement.scrollTop

      bottom = bottom < offset ? 20 : bottom - offset

      iframeStyle.value = {
        bottom: bottom + 'px'
      }
    }

    return {
      snackbar,
      iframeStyle,
      onSnackbarClick,
      onActionClick
    }
  }
})
