import { CIcon } from '~/icons/types'

export const ciGlobe: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M20.5 7.2c-1.7-3.1-5-5-8.5-5s-6.8 1.9-8.5 5c-.8 1.5-1.3 3.1-1.3 4.8 0 .8.1 1.6.3 2.4 1.1 4.3 5 7.3 9.4 7.3s8.3-3 9.4-7.3c.2-.8.3-1.6.3-2.4.2-1.7-.3-3.3-1.1-4.8Zm-1.6.2c-.6.5-1.2.9-1.9 1.2-.3-1.6-.9-3.1-1.6-4.1 1.4.6 2.6 1.6 3.5 2.9ZM15.8 12c0 1.2-.1 2.4-.4 3.4-2.2.4-4.6.4-6.8 0-.2-1-.4-2.2-.4-3.4v-1.3c2.4.7 5 .7 7.4 0 .1.4.2.9.2 1.3ZM12 3.8c1.4 0 2.9 2.1 3.5 5.4-2.3.7-4.8.7-7 0 .6-3.3 2.1-5.4 3.5-5.4Zm-3.4.7C7.9 5.6 7.4 7 7.1 8.6c-.7-.3-1.4-.7-2-1.2.9-1.3 2.1-2.3 3.5-2.9ZM3.8 12c0-1.1.2-2.2.7-3.3.8.6 1.6 1 2.4 1.4-.1.6-.1 1.2-.1 1.8 0 1.1.1 2.1.2 3-1.1-.3-2.1-.7-3.1-1.3-.1-.4-.1-1-.1-1.6Zm.8 3.8c.9.4 1.8.7 2.7.9.3 1.1.7 2.1 1.3 2.9-1.7-.8-3.1-2.2-4-3.8Zm7.4 4.5c-1.1 0-2.2-1.2-2.9-3.2 1.9.3 3.9.3 5.9 0-.8 1.9-1.9 3.2-3 3.2Zm3.4-.8c.5-.8.9-1.8 1.3-2.9.9-.2 1.8-.5 2.7-.9-.9 1.7-2.3 3.1-4 3.8Zm4.7-5.7c-1 .5-2 .9-3.1 1.3.2-.9.2-2 .2-3 0-.6 0-1.2-.1-1.8.9-.4 1.7-.8 2.4-1.4.4 1 .7 2.1.7 3.3 0 .4 0 1-.1 1.6Z',
      fill: 'currentColor'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'globe',
  type: 'light'
}
