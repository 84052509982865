import { TranslateResult } from 'vue-i18n'
import { CIcon } from '~/icons/types'

export interface HeaderDropdown {
  title: string | TranslateResult
  subtitles?: string[] | TranslateResult[]
  links: HeaderDropdownLink[][]
  hide?: boolean
  dropdownPosition: DropdownPosition
}

export enum DropdownPosition {
  CENTER = 'center',
  LEFT = 'left',
  DEFAULT = CENTER
}

export interface HeaderDropdownLink {
  /* eslint-disable camelcase */
  title: string | TranslateResult
  url: string
  icon?: CIcon
  extra_classes?: string
  iconClasses?: string
  new_label?: boolean
  useNuxtLink?: boolean
  hasFaIcon?: boolean
  hide?: boolean
  externalLink?: boolean
  useAnonGuard?: boolean
  label?: number | string | TranslateResult
}

export interface HeaderDropdownLinkClickEvent {
  link: HeaderDropdownLink
  event: Event
}

export enum HeaderDropdownId {
  LOCALE = 'locale',
  USER = 'user',
  MY_CLASSIFIEDS = 'my-classifieds',
  USER_REGISTRATION = 'user-registration'
}
