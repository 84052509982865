import { CIcon } from '~/icons/types'
export const ciFolderOpen: CIcon = {
  width: 400,
  height: 266,
  name: 'folder-open',
  type: 'regular',
  paths: [
    {
      d:
        'M25.318.969C13.605 3.814 3.249 14.481.774 26.249-.32 31.453-.32 235.213.774 240.417c2.603 12.377 13.575 23.164 25.962 25.523 3.063.583 41.344.738 144.445.583l140.277-.211 5.19-1.604c19.746-6.104 22.361-9.103 54.045-61.984 32.85-54.827 33.487-56.624 25.706-72.49-7.806-15.918-13.941-18.665-42.406-18.984l-20.66-.232V92.696c0-24.781-1.025-28.881-9.453-37.815-10.031-10.634-8.56-10.437-77.848-10.437h-57.371l-22.123-22.222L144.415 0 86.617.059c-43.06.044-58.69.276-61.299.91m131.963 43.465 22.203 22.211 62.291.184c61.685.183 62.309.199 64.208 1.617 4.807 3.589 4.76 3.362 4.986 23.949l.206 18.685-101.595.197-101.594.198-5.089 1.572c-19.68 6.078-20.475 7.013-56.417 66.372l-23.911 39.489-.196-92.961c-.212-99.845-.297-96.369 2.447-99.63 3.524-4.188 1.376-4.038 58.17-4.068l52.088-.027 22.203 22.212m215.411 90.676c2.977 2.171 5.236 7.012 4.879 10.456-.421 4.063-48.475 84.233-53.728 89.636-3.919 4.032-10.068 7.35-15.812 8.533-2.389.492-47.301.666-140.082.543l-136.565-.181 29.349-48.958c31.019-51.743 32.825-54.37 39.825-57.917 7.952-4.03-.293-3.814 140.537-3.672l129.637.131 1.96 1.429',
      fill: 'currentColor'
    }
  ]
}
