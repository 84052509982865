import { CIcon } from '~/icons/types'

export const ciEnglish: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M7.523.865A11.976 11.976 0 0 0 3.93 3.121L7.523 5.28V.865ZM3.929 20.879a11.978 11.978 0 0 0 3.594 2.256V18.72L3.93 20.88ZM20.071 3.121A11.977 11.977 0 0 0 16.477.865V5.28l3.593-2.16Zm-3.594 20.014a11.98 11.98 0 0 0 3.594-2.256l-3.594-2.158v4.414Z',
      fill: '#012169'
    },
    {
      d:
        'M16.477 7.523h6.658a11.38 11.38 0 0 0-.849-1.696 12.438 12.438 0 0 0-1.378-1.864l-4.431 2.66v.9Zm-8.954 0v-.9l-4.43-2.66a11.6 11.6 0 0 0-1.38 1.864 12.924 12.924 0 0 0-.848 1.696h6.658Zm8.954 8.954v.9l4.43 2.66a11.54 11.54 0 0 0 1.38-1.864c.323-.542.605-1.108.848-1.696h-6.658Zm-8.954 0H.865c.237.588.52 1.16.848 1.696.399.663.866 1.287 1.38 1.863l4.43-2.66v-.9Z',
      fill: '#C8102E'
    },
    {
      d:
        'M23.804 9.83h-9.635V.197A12.231 12.231 0 0 0 12 0c-.739 0-1.465.07-2.17.196v9.635H.197A12.21 12.21 0 0 0 0 12c0 .739.07 1.465.196 2.17h9.635v9.634c.704.127 1.43.196 2.169.196.739 0 1.465-.07 2.17-.196v-9.635h9.634C23.93 13.465 24 12.74 24 12c0-.739-.07-1.465-.196-2.17Z',
      fill: '#C8102E'
    },
    {
      d:
        'M20.1 14.17h-5.931v9.634c.393-.07.779-.162 1.154-.271.392-.116.779-.248 1.154-.398V18.72l3.594 2.158c.294-.266.571-.548.837-.843l-4.431-2.66v-.9h6.658c.051-.132.103-.264.15-.397a11.503 11.503 0 0 0 .519-1.91H20.1Zm-5.93-6.162V9.83h9.634c-.046-.26-.104-.52-.167-.773-.035-.127-.064-.254-.104-.381a11.51 11.51 0 0 0-.248-.756c-.047-.133-.099-.265-.15-.398h-6.658v-.9l4.43-2.66a12.777 12.777 0 0 0-.836-.842l-3.594 2.158V.865c-.375-.15-.762-.282-1.154-.398-.375-.11-.761-.196-1.154-.271v7.812ZM3.9 9.83h5.931V.197c-.393.07-.78.162-1.154.271a12.95 12.95 0 0 0-1.154.398V5.28L3.93 3.12c-.294.265-.571.548-.837.842l4.431 2.66v.9H.865a12.89 12.89 0 0 0-.15.398 11.566 11.566 0 0 0-.519 1.91H3.9Zm5.93 6.162V14.17H.197c.046.26.104.52.167.773.035.127.064.254.104.381.075.254.156.508.248.756.047.133.098.265.15.398h6.657v.9l-4.43 2.66c.265.294.547.576.836.842l3.594-2.158v4.414a11.965 11.965 0 0 0 2.308.668v-7.811Z',
      fill: '#fff'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'english',
  type: 'brand'
}
