import {
  isRef,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch
} from '@nuxtjs/composition-api'

export enum WebSocketStatus {
  OPEN = 'open',
  CONNECTING = 'connecting',
  CLOSED = 'closed'
}

export interface UseWebSocketOptions {
  onConnected?: (ws: WebSocket) => void
  onDisconnected?: (ws: WebSocket, event: CloseEvent) => void
  onError?: (ws: WebSocket, event: Event) => void
  onMessage?: (ws: WebSocket, event: MessageEvent) => void
}

export function useWebSocket<Data = any>(
  url: string | Ref<string>,
  options: UseWebSocketOptions = {}
) {
  const { onConnected, onDisconnected, onError, onMessage } = options

  const data: Ref<Data | null> = ref(null)
  const status = ref<WebSocketStatus>(WebSocketStatus.CLOSED)
  const wsRef = ref<WebSocket>()
  const urlRef = isRef(url) ? url : ref(url)

  function createWebSocket() {
    if (!urlRef.value || process.server) {
      return
    }

    const ws = new WebSocket(urlRef.value)
    wsRef.value = ws
    status.value = WebSocketStatus.CONNECTING

    ws.onopen = () => {
      status.value = WebSocketStatus.OPEN
      if (onConnected) {
        onConnected(ws!)
      }
    }

    ws.onclose = event => {
      status.value = WebSocketStatus.CLOSED
      wsRef.value = undefined
      if (onDisconnected) {
        onDisconnected(ws, event)
      }
    }

    ws.onerror = event => {
      if (onError) {
        onError(ws!, event)
      }
    }

    ws.onmessage = (event: MessageEvent) => {
      data.value = event.data
      if (onMessage) {
        onMessage(ws!, event)
      }
    }
  }

  function setUrl(url: string) {
    urlRef.value = url
  }

  function open() {
    close()
    createWebSocket()
  }

  function close(code = 1000, reason?: string) {
    if (!process.client || !wsRef.value) {
      return
    }

    wsRef.value.close(code, reason)
  }

  function send(data: string | ArrayBuffer | Blob | DataView) {
    if (!wsRef.value || status.value !== 'open') {
      return
    }

    wsRef.value.send(data)
  }

  watch(urlRef, () => {
    open()
  })

  const onBeforeUnload = (_e: Event) => {
    close()
    // @ts-ignore
    _e = null
  }

  onMounted(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', onBeforeUnload)
    }
  })

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }

    close()
  })

  return {
    data,
    status,
    close,
    send,
    open,
    setUrl,
    ws: wsRef
  }
}
