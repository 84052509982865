import { CIcon } from '~/icons/types'

export const ciUserCircle: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 1C5.9 1 1 5.9 1 12c0 3.2 1.3 6 3.5 8 2 1.9 4.6 3 7.5 3 2.9 0 5.6-1.1 7.5-3 2.1-2 3.5-4.9 3.5-8 0-6.1-4.9-11-11-11Zm0 3.4c2.3 0 4.2 1.9 4.2 4.2 0 2.3-1.9 4.2-4.2 4.2-2.3 0-4.2-1.8-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2Zm0 16.9c-2.6 0-4.9-1.1-6.6-2.8 1.6-1.9 2.9-3.2 6.6-3.2 3.7 0 5.1 1.2 6.6 3.2-1.7 1.8-4 2.8-6.6 2.8Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'user-circle',
  type: 'solid'
}
