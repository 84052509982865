







import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    flashData: {
      type: Object,
      required: true
    }
  },
  computed: {
    email() {
      return this.flashData.email || '<>'
    }
  }
})
